/* eslint-disable max-len */
/* eslint-disable no-useless-constructor */
class NavigationSchnelleinstiegTags extends HTMLElement {
    constructor() {
      super();
    }
  
    connectedCallback() {
      this.getElements();
      this.addEvents();
      this.controls.setAttribute('active', '');
      this.observeControls();
    }
  
    getElements() {
      this.slider = this.querySelector('.linklist');
			this.deeplinkBoxes = this.querySelectorAll('.deeplink-box');
      this.controls = this.querySelector('.controls');
      this.next = this.querySelector('.next');
      this.prev = this.querySelector('.prev');
    }
  
    addEvents() {
      this.next.addEventListener('click', () => {
				const nextItem = this.getNextVisibleItem();
				this.scrollToPosition(nextItem.offsetLeft);
      });
      this.prev.addEventListener('click', () => {
				const prevItem = this.getPrevVisibleItem();
				// Die `100` ist eine "magische" Konstante, mit ihr wird sichergestellt, dass etwas über das
				// Ziel hinaus gescrollt wird und so das Element (und eventuell auch das vorherige) sichtbar sind.
				this.scrollToPosition((prevItem.offsetLeft - 100));
      });
    }

		getNextVisibleItem() {
			const containerRect = this.slider.getBoundingClientRect();
			const items = Array.from(this.deeplinkBoxes);

			// Find the first item that's not fully visible on the right
			for (let item of items) {
				const itemRect = item.getBoundingClientRect();

				// If the item's right edge is beyond the container's right edge,
				// this is the next item we should scroll to
				if (itemRect.right > containerRect.right) {
					return item;
				}
			}

			// If all items are visible, return the last item
			return items[items.length - 1];
		}

		getPrevVisibleItem() {
			const containerRect = this.slider.getBoundingClientRect();
			const items = Array.from(this.deeplinkBoxes);
			let lastVisibleItem = null;

			// Find the last fully visible item from left
			for (let i = items.length - 1; i >= 0; i--) {
				const item = items[i];
				const itemRect = item.getBoundingClientRect();

				// If this item is partially or fully visible
				if (itemRect.left < containerRect.right) {
					// If this item starts before the container's left edge,
					// we want to scroll to it
					if (itemRect.left < containerRect.left) {
						return item;
					}
					// Otherwise, keep track of the last visible item
					else if (!lastVisibleItem) {
						lastVisibleItem = item;
					}
				}
			}

			// If we didn't find an item starting before the container's left edge,
			// try to get the item before the first visible one
			if (lastVisibleItem) {
				const index = items.indexOf(lastVisibleItem);
				if (index > 0) {
					return items[index - 1];
				}
			}

			// If we couldn't find a suitable item, return the first item
			return items[0];
		}
  
    scrollToPosition(x) {
      this.slider.scrollTo({
        top: 0,
        left: x,
        behavior: 'smooth'
      });
    }
  
    static getContainerHeight(target) {
      return target.offsetHeight();
    }
  
    observeControls() {
      this.sliderItemFirst = this.slider.querySelector('.deeplink-box:first-of-type');
      this.sliderItemLast = this.slider.querySelector('.deeplink-box:last-of-type');
      this.options = {
        root: this.slider,
        threshold: [0.8, 1]
      };
      this.observer = new IntersectionObserver((entries) => {
        this.next.style.display = 'inline-flex';
        this.prev.style.display = 'inline-flex';
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.8 && entry.target === this.sliderItemFirst) {
            this.prev.style.display = 'none';
          }
          if (entry.isIntersecting && entry.intersectionRatio >= 0.8 && entry.target === this.sliderItemLast) {
            this.next.style.display = 'none';
          }
        });
      }, this.options);
      this.observer.observe(this.sliderItemFirst);
      this.observer.observe(this.sliderItemLast);
    }
  }
  
  if (!customElements.get('navigation-schnelleinstieg-tags')) {
    customElements.define('navigation-schnelleinstieg-tags', NavigationSchnelleinstiegTags);
  }
